import React, { useEffect, useState, useRef } from 'react';
import '../../contents/styles/home/no-page.css';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import useTranslation from '../../components/customHooks/translations';
import { getAbnormalSettings, deleteAbnormalSettings } from '../../apiInterface/dataManagement/AbnormalSettingsAPI';
import { getLanguage, getUser, getIP, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import CustomStore from 'devextreme/data/custom_store';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { getSysDivisionCaptionByCode } from '../../apiInterface/menu/MenuAPI';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import UpDown from '../../components/common/UpDown';
import Badge from '../../components/common/Badge.jsx';

const AbnormalSettings = () => {
    const lang = getLanguage();
    const user = getUser();
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const searchText = useRef('');
    const [abnormalTypeList, setAbnormalTypeList] = useState();
    const [curAbnormalType, setCurAbnormalType] = useState();
    const [customDataSource, setCustomDataSource] = useState([]);
    const upDownClass = { 높음: 'dx-icon-m-arrow-increase', 낮음: 'dx-icon-m-arrow-decrease' };
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: 'grid-badge-use', false: 'grid-badge-disabled' };

    useEffect(() => {
        getDivisionCaption();
    }, []);

    useEffect(() => {
        getAbnormalSettingsInfo();
    }, [curAbnormalType, searchText]);

    const getDivisionCaption = async () => {
        const params = {
            user_company_id: user.user_company_id,
            sys_division_code: 'ABNORMALTYPE',
            sys_division_sub_lang_code: lang,
        };
        const res = await errorHandler(getSysDivisionCaptionByCode, params);
        if (res !== 'fail' && res.message === 'MSG_100') {
            const typeList = [{ sys_division_sub_caption_text: translation.all, sys_division_sub_value: '' }, ...res.data.o_data];

            setAbnormalTypeList(typeList);
            setCurAbnormalType(typeList[0]);
        }
    };

    const getAbnormalSettingsInfo = () => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                let skip = 0;
                let take = 10;
                if (loadOptions['skip']) skip = loadOptions['skip'];
                if (loadOptions['take']) take = loadOptions['take'];
                const pageNo = parseInt(skip / take) + 1;
                const param = {
                    user_company_id: user.user_company_id,
                    sys_lang_code: lang,
                    row_count: take,
                    page_no: pageNo,
                    abnormal_setting_type: curAbnormalType?.sys_division_sub_value,
                    search_text: searchText.current,
                };
                const res = await errorHandler(getAbnormalSettings, param);
                if (res) {
                    return {
                        data: res.data.o_data ? res.data.o_data : [],
                        totalCount: res.data?.count ? res.data?.count : 0,
                    };
                }
                return { data: [], totalCount: 0 };
            },
            remove: async (key) => {
                deleteBadTypeInfo(key);
            },
        });
        //setCustomStoreObj(customStore);
        setCustomDataSource(customStore);
    };

    const header = [
        { dataField: 'abnormal_setting_no', caption: translation.abnormal_setting_no.toUpperCase(), dataType: 'number', isEdit: false, width: 100, alignment: 'center', allowReordering: true, isFixed: true },
        //{ dataField: 'abnormal_setting_type', caption: translation.abnormal_setting_type.toUpperCase(), dataType: 'string', isEdit: false, width: 100, allowReordering: true, isFixed: true },
        { dataField: 'id_short', caption: translation.id_short.toUpperCase(), dataType: 'string', isEdit: false, allowReordering: true, isFixed: true },
        { dataField: 'dataset_master_name', caption: translation.dataset_name.toUpperCase(), dataType: 'string', isEdit: false, allowReordering: true, isFixed: true },
        { dataField: 'abnormal_setting_factory', caption: translation.abnormal_setting_factory.toUpperCase(), dataType: 'string', isEdit: false, allowReordering: true, isFixed: true },
        { dataField: 'abnormal_setting_line', caption: translation.abnormal_setting_line.toUpperCase(), dataType: 'string', isEdit: false, allowReordering: true, isFixed: true },
        { dataField: 'abnormal_setting_process', caption: translation.abnormal_setting_process.toUpperCase(), dataType: 'string', isEdit: false, allowReordering: true, isFixed: true },
        { dataField: 'abnormal_setting_machine', caption: translation.abnormal_setting_machine.toUpperCase(), dataType: 'string', isEdit: false, allowReordering: true, isFixed: true },
        //{ dataField: 'abnormal_setting_item', caption: translation.abnormal_setting_item.toUpperCase(), dataType: 'string', isEdit: false, allowReordering: true, isFixed: true },
        { dataField: 'abnormal_setting_usl', caption: translation.abnormal_setting_usl.toUpperCase(), dataType: 'string', isEdit: false, width: 100, alignment: 'center', allowReordering: true, isFixed: true },
        { dataField: 'abnormal_setting_lsl', caption: translation.abnormal_setting_lsl.toUpperCase(), dataType: 'string', isEdit: false, width: 100, alignment: 'center', allowReordering: true, isFixed: true },

        // 2024-03-07 :: add ucl, lcl, remove abnormal_setting_status, add isUse
        { dataField: 'abnormal_setting_ucl', caption: translation.abnormal_setting_ucl.toUpperCase(), dataType: 'string', isEdit: false, width: 100, alignment: 'center', allowReordering: true, isFixed: true },
        { dataField: 'abnormal_setting_lcl', caption: translation.abnormal_setting_lcl.toUpperCase(), dataType: 'string', isEdit: false, width: 100, alignment: 'center', allowReordering: true, isFixed: true },
        {
            dataField: 'abnormal_setting_is_active',
            caption: translation.use_or_not,
            dataType: 'boolean',
            width: 130,
            alignment: 'center',
            width: 130,
            alignment: 'center',
            isFixed: true,
            cellRender: (cell) => <Badge className={activeNotActiveClassName[cell.value]} text={activeNotActiveJson[cell.value]} />,
        },

        // {
        //     dataField: 'abnormal_setting_status',
        //     caption: translation.abnormal_setting_status.toUpperCase(),
        //     dataType: 'string',
        //     isEdit: false,
        //     width: 100,
        //     alignment: 'center',
        //     allowReordering: true,
        //     isFixed: true,
        //     cellRender: (e) => <UpDown className={upDownClass[e.value]} text={e.value} />,
        // },
        //{ dataField: 'abnormal_setting_reason', caption: translation.abnormal_setting_reason.toUpperCase(), dataType: 'string', isEdit: false, allowReordering: true, isFixed: true },
        //{ dataField: 'abnormal_setting_recommend', caption: translation.abnormal_setting_recommend.toUpperCase(), dataType: 'string', isEdit: false, allowReordering: true, isFixed: true },
        { dataField: 'abnormal_setting_modified_date', caption: translation.lastModifiedDate.toUpperCase(), dataType: 'string', isEdit: false, width: 300, alignment: 'center', allowReordering: true, isFixed: true },
        { dataField: 'abnormal_setting_modified_by', caption: translation.lastModifiedBy.toUpperCase(), dataType: 'string', isEdit: false, width: 300, allowReordering: true, isFixed: true },
    ];

    const abnormalTypeDropdown = <DropdownField width={204} label={translation.select_setting_type} value={curAbnormalType} onValueChanged={(e) => setCurAbnormalType(e.value)} dataSource={abnormalTypeList} displayExpr="sys_division_sub_caption_text" />;

    const onFilter = (keyword) => {
        searchText.current = keyword;
        getAbnormalSettingsInfo();
    };

    // delete
    const deleteBadTypeInfo = async (selectData) => {
        console.log(selectData);
        const user = getUser();
        const ipAddress = getIP();
        const menuMappingInfo = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: menuMappingInfo.id,
            user_company_id: user.user_company_id,
            abnormal_setting_id: selectData.abnormal_setting_id,
        };

        const res = await errorHandler(deleteAbnormalSettings, queryData);
        if (res) {
            // successToastr(translation.success_delete_row);
        }
    };

    const filter = <FilterComponent label={translation.filter} width={204} onFilter={onFilter} />;
    //const filter = <SearchWithTag items={["item name", "item code", "equipment name", "equipment code", "mold name", "mold code"]} />

    return (
        <div className="abnormal-settings">
            <div className="right-content-title">
                <PageTitle />
            </div>
            <div className="right-content-body">
                <div className="container">
                    <BaseDataGrid
                        toolbarModules={[abnormalTypeDropdown, filter]}
                        dataSource={customDataSource}
                        link="AbnormalSettingsForm"
                        isRemote={true} // about delete multiple
                        headers={header}
                        selectMode="multiple"
                        isPaging={true}
                        isFullSize={true} // ?
                        params={{ curAbnormalType }}
                    />
                </div>
            </div>
        </div>
    );
};

export default AbnormalSettings;
