import React, { useEffect, useState, useRef } from 'react';
import { getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslation from '../../components/customHooks/translations';
import { useSwal } from '../../components/common/Swal';
import { TextboxField, SearchableField, DropdownField, FilterComponent } from '../../components/common/FormField';
import '../../contents/styles/item/item-master-form.css';
import '../../contents/styles/dataManagement/abnormal-setting.css';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import SelectRawDatasetPopup from './SelectRawDataPopup';
import { getOneAbnormalSetting, getSysDivisionCaptionByCode, insertAbnormalSettings, updateAbnormalSettings } from '../../apiInterface/dataManagement/AbnormalSettingsAPI';
import SelectIdShortPopup from './SelectIdShortPopup';
import { NumericRule } from 'devextreme-react/validator';
import { Popup, ScrollView } from 'devextreme-react';
import BaseDataGrid from '../../components/common/BaseDataGrid.jsx';
import { DataFilter } from '../../components/gridStackPane/FilterSetting.jsx';
import { getIdShorts } from '../../apiInterface/dataManagement/AbnormalSettingsAPI';
import useErrorHandler from '../../components/customHooks/useErrorHandler.js';

export default function AbnormalSettingsForm() {
    const navigate = useNavigate();
    const user = getUser();
    const ipAddr = getIP();
    const lang = getLanguage();
    const menuMappingInfo = getMenuMappingInfo();
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const location = useLocation();
    const { success, fail } = useSwal();
    const { title, curAbnormalType, rowData } = location.state;
    const [data, setData] = useState({});
    const [abnormalStatusList, setAbnormalStatusList] = useState();
    const [isRawDatasetPopupVisible, setRawDatasetPopupVisibility] = useState(false);
    const [isIdShortPopupVisible, setIdShortsetPopupVisibility] = useState(false);
    const [abnormalTypeList, setAbnormalTypeList] = useState();
    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];
    const [filterDataSource, setFilterDataSource] = useState([]);
    const [isAddFilterPopup, setAddFilterPopup] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const [selectedRawData, setSelectedRawData] = useState([]);
    const [selectedIdShort, setSelectedIdShort] = useState([]);

    const heaterConditionList = [
        {
            sys_division_sub_caption_text: 'Min / Max',
            sys_division_sub_value: 'MINMAX',
            sys_division_sub_seq: 1,
            sys_division_sub_default_check: null,
        },
        {
            sys_division_sub_caption_text: '=',
            sys_division_sub_value: 'EQUAL',
            sys_division_sub_seq: 2,
            sys_division_sub_default_check: null,
        },
    ];

    useEffect(() => {
        // getDivisionCaption();
        getAbnormalSettingType();
        getGridInfo();

        if (title === 'Edit') getOneAbnormalSettingsInfo(rowData.abnormal_setting_id);
        else {
            valueChanged(heaterConditionList[0].sys_division_sub_value, 'abnormal_settings_condition_direction');
            valueChanged(useItems[0].value, 'abnormal_setting_is_active');
        }
    }, []);

    const getDivisionCaption = async () => {
        const params = {
            user_company_id: user.user_company_id,
            sys_division_code: 'ABNORMALSTATUS',
            sys_division_sub_lang_code: lang,
        };
        const res = await getSysDivisionCaptionByCode(params);
        if (res) {
            const statusList = res.data.o_data.map((t) => {
                return { value: t.sys_division_sub_value, title: t.sys_division_sub_caption_text };
            });
            setAbnormalStatusList(statusList);
            valueChanged(statusList[0].value, 'abnormal_setting_status');
        }
    };

    const getOneAbnormalSettingsInfo = async (id) => {
        const params = {
            user_company_id: user.user_company_id,
            abnormal_setting_id: id,
            sys_lang_code: lang,
        };
        const res = await getOneAbnormalSetting(params);
        if (res !== 'fail' && res.message === 'MSG_100') {
            const data = res.data.o_data[0];
            if (data) {
                data['abnormal_settings_condition_direction'] = heaterConditionList?.[0]?.sys_division_sub_value;

                if (data?.abnormal_setting_filter) {
                    // console.log(Object.keys(data?.abnormal_setting_filter));
                    if (Object.keys(data?.abnormal_setting_filter).length !== 0) {
                        //     let strToJson = JSON.parse(data?.abnormal_setting_filter);
                        let strToJson = data?.abnormal_setting_filter;
                        if (strToJson.length !== 0) setFilterList(strToJson);

                        // data['abnormal_settings_condition_direction'] = strToJson?.abnormal_settings_condition_direction;
                        // data['abnormal_settings_condition_max'] = strToJson?.abnormal_settings_condition_max;
                        // data['abnormal_settings_condition_min'] = strToJson?.abnormal_settings_condition_min;
                        // data['abnormal_settings_condition_equal'] = strToJson?.abnormal_settings_condition_equal;
                    }
                }
                setData(data);
                getGridInfo(data?.dataset_master_id);

                // selected data
                // setSelectedRawData([{ dataset_master_id: data?.dataset_master_id, dataset_master_name: data?.dataset_master_name }]);
                // setSelectedIdShort([{ column_name: data?.id_short }]);
            }
        }
    };

    const getAbnormalSettingType = async () => {
        const params = {
            user_company_id: user.user_company_id,
            sys_division_code: 'ABNORMALTYPE',
            sys_division_sub_lang_code: lang,
        };
        const res = await getSysDivisionCaptionByCode(params);
        if (res !== 'fail' && res.message === 'MSG_100') {
            const typeList = res.data.o_data;

            setAbnormalTypeList(typeList);
            valueChanged(typeList[0].sys_division_sub_value, 'abnormal_setting_type');
        }
    };

    const onInsert = async () => {
        // let newObj = {
        //     abnormal_settings_condition_direction: data?.abnormal_settings_condition_direction,
        //     abnormal_settings_condition_max: data?.abnormal_settings_condition_max ? data?.abnormal_settings_condition_max : null,
        //     abnormal_settings_condition_min: data?.abnormal_settings_condition_min ? data?.abnormal_settings_condition_min : null,
        //     abnormal_settings_condition_equal: data?.abnormal_settings_condition_equal ? data?.abnormal_settings_condition_equal : null,
        // };
        // data['abnormal_setting_filter'] = JSON.stringify(newObj);
        data['abnormal_setting_filter'] = JSON.stringify(filterList);
        delete data['abnormal_settings_condition_direction'];
        delete data['abnormal_settings_condition_max'];
        delete data['abnormal_settings_condition_min'];
        delete data['abnormal_settings_condition_equal'];

        const params = {
            ...data,
            user_log_ip: ipAddr,
            user_master_id: user.user_master_id,
            user_menu_mapping_id: menuMappingInfo.id,
            user_company_id: user.user_company_id,
            user_master_email: user.user_master_email,
        };

        params.abnormal_setting_lsl = parseInt(params.abnormal_setting_lsl);
        params.abnormal_setting_usl = parseInt(params.abnormal_setting_usl);
        params.abnormal_setting_ucl = parseInt(params.abnormal_setting_ucl);
        params.abnormal_setting_lcl = parseInt(params.abnormal_setting_lcl);
        delete params['dataset_master_name'];

        const res = await insertAbnormalSettings(params);
        if (res !== 'fail' && res.message === 'MSG_100') {
            const cb = () => exitForm('/AbnormalSettings');
            success(translation.success_insert, cb);
        } else {
            fail(translation?.[res.message]);
        }
    };

    const onUpdate = async () => {
        // let newObj = {
        //     abnormal_settings_condition_direction: data?.abnormal_settings_condition_direction,
        //     abnormal_settings_condition_max: data?.abnormal_settings_condition_max ? data?.abnormal_settings_condition_max : null,
        //     abnormal_settings_condition_min: data?.abnormal_settings_condition_min ? data?.abnormal_settings_condition_min : null,
        //     abnormal_settings_condition_equal: data?.abnormal_settings_condition_equal ? data?.abnormal_settings_condition_equal : null,
        // };
        // data['abnormal_setting_filter'] = JSON.stringify(newObj);
        data['abnormal_setting_filter'] = JSON.stringify(filterList);
        delete data['abnormal_settings_condition_direction'];
        delete data['abnormal_settings_condition_max'];
        delete data['abnormal_settings_condition_min'];
        delete data['abnormal_settings_condition_equal'];

        const params = {
            ...data,
            // abnormal_setting_type: curAbnormalType.sys_division_sub_value,
            user_log_ip: ipAddr,
            user_master_id: user.user_master_id,
            user_menu_mapping_id: menuMappingInfo.id,
            user_company_id: user.user_company_id,
            user_master_email: user.user_master_email,
        };
        delete params['dataset_master_name'];
        delete params['modified_by_department'];
        delete params['modified_by_duty'];
        delete params['modified_date'];
        delete params['abnormal_setting_status'];
        params.abnormal_setting_lsl = parseInt(params.abnormal_setting_lsl);
        params.abnormal_setting_usl = parseInt(params.abnormal_setting_usl);
        params.abnormal_setting_ucl = parseInt(params.abnormal_setting_ucl);
        params.abnormal_setting_lcl = parseInt(params.abnormal_setting_lcl);

        const res = await updateAbnormalSettings(params);
        if (res !== 'fail' && res.message === 'MSG_100') {
            const cb = () => exitForm('/AbnormalSettings');
            success(translation.success_update, cb);
        } else {
            fail(translation?.[res.message]);
        }
    };

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    const rawDatasetSelected = (e) => {
        setSelectedRawData(e);

        e.forEach((d) => {
            valueChanged(d.dataset_master_name, 'dataset_master_name');
            valueChanged(d.dataset_master_id, 'dataset_master_id');
            //getIdShortsList(d.dataset_master_id);
        });
    };

    const idShortSelected = (e) => {
        setSelectedIdShort(e.selectedRows);

        e.selectedRows.forEach((d) => {
            valueChanged(d?.column_name, 'id_short');
            valueChanged(d?.id, 'aas_master_id');
        });

        setFilterDataSource(e.dataSource);
    };

    const exitForm = (targetUrl) => {
        navigate(targetUrl, {
            state: { previousPage: location.pathname },
        });
    };

    const submit = (e) => {
        e.preventDefault();
        if (title === 'Add') onInsert();
        else if (title === 'Edit') onUpdate();
    };

    const validationOfText = <NumericRule message="aaa" />;

    //
    const onAddFilterList = (arr) => {
        arr.map((m) => {
            let json = typeof m?.title_json === 'string' ? JSON.parse(m?.title_json) : m?.title_json;
            let find = json.filter((f) => f?.sys_lang_code === lang)?.[0];
            if (find) m['column_title'] = find?.sys_lang_code_title;

            // set value
            if (data?.abnormal_setting_filter) {
                let find = data?.abnormal_setting_filter.find((f) => f?.column_name === m?.column_name);
                if (find) m['setting'] = find?.setting;
            }
        });

        setFilterList(arr);

        setAddFilterPopup(false);
    };

    const getGridInfo = async (id) => {
        if (!id) return;

        const params = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
            dataset_master_id: id,
            search_division: 'ALL',
        };
        const res = await errorHandler(getIdShorts, params);
        if (res) {
            const data = res.data.o_data;
            if (data.length !== 0) {
                setFilterDataSource(data);
            }
        }
    };

    return (
        <div className="item-master-form">
            <div className="right-content-title">
                <PageTitle pageState={title} />
            </div>
            <form onSubmit={submit}>
                <div className="right-content-body">
                    <div className="container">
                        <div className="dx-fieldset">
                            <DropdownField label={translation.select_setting_type} value={data?.abnormal_setting_type} dataSource={abnormalTypeList} valueExpr="sys_division_sub_value" displayExpr="sys_division_sub_caption_text" onValueChanged={(e) => valueChanged(e.value, 'abnormal_setting_type')} />
                            <SearchableField isEssential={false} label={translation.raw_dataset} hasTextboxPopup={true} onSearch={() => title === 'Add' && setRawDatasetPopupVisibility(true)} text={data?.dataset_master_name} disabled={title === 'Add' ? false : true} />
                            <SearchableField isEssential={false} label={translation.id_short} hasTextboxPopup={true} onSearch={() => title === 'Add' && data?.dataset_master_id && setIdShortsetPopupVisibility(true)} text={data?.id_short} disabled={title === 'Add' && data?.dataset_master_id ? false : true} />

                            {/* 2024-03-07 :: add heat filter */}
                            {/* <div>
                                <DropdownField
                                    label={translation.heater_temperature}
                                    value={data?.abnormal_settings_condition_direction}
                                    dataSource={heaterConditionList}
                                    valueExpr="sys_division_sub_value"
                                    displayExpr="sys_division_sub_caption_text"
                                    onValueChanged={(e) => {
                                        valueChanged(e.value, 'abnormal_settings_condition_direction');
                                        valueChanged('', 'abnormal_settings_condition_max');
                                        valueChanged('', 'abnormal_settings_condition_min');
                                        valueChanged('', 'abnormal_settings_condition_equal');
                                    }}
                                />
                                <div className={`${data?.abnormal_settings_condition_direction === 'EQUAL' && 'hidden'}`}>
                                    <TextboxField value={data?.abnormal_settings_condition_max} onValueChanged={(e) => valueChanged(e.value, 'abnormal_settings_condition_max')} placeHolder="min" />
                                    <TextboxField value={data?.abnormal_settings_condition_min} onValueChanged={(e) => valueChanged(e.value, 'abnormal_settings_condition_min')} placeHolder="max" />
                                </div>
                                <div className={`${data?.abnormal_settings_condition_direction === 'MINMAX' && 'hidden'}`}>
                                    <TextboxField value={data?.abnormal_settings_condition_equal} onValueChanged={(e) => valueChanged(e.value, 'abnormal_settings_condition_equal')} />
                                </div>
                            </div> */}
                            <div className="dx-field column">
                                <Button
                                    type="default"
                                    stylingMode="contained"
                                    icon="add"
                                    // text={`${translation.add_filter} (${gridDataFilterInfo?.filter_list.length})`}
                                    text={`${translation.add_filter} (${filterList.length})`}
                                    className="btn-s dx-button-mode-es"
                                    width="100%"
                                    disabled={data?.id_short ? false : true}
                                    onClick={() => setAddFilterPopup(true)}
                                />
                            </div>

                            {filterList.length !== 0 && (
                                <div className="abnormal-filter">
                                    {filterList.map((m, idx) => {
                                        return (
                                            <DataFilter
                                                key={idx}
                                                idx={idx}
                                                label={m?.column_title}
                                                type={m?.datatype}
                                                value={m?.setting}
                                                filterValueChanged={(idx, label, value) => {
                                                    m['setting'] = value;
                                                }}
                                                // clickFilter={(val) => console.log('clickFilter', val)}
                                                deleteFilter={() => {
                                                    const find = filterList.filter((f, i) => i !== idx);
                                                    setFilterList(find);
                                                    // reset
                                                    setData({ ...data, abnormal_setting_filter: find });
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            )}

                            <TextboxField label={translation.abnormal_setting_usl} value={data?.abnormal_setting_usl?.toString()} onValueChanged={(e) => valueChanged(e.value, 'abnormal_setting_usl')} validationRules={validationOfText} />
                            <TextboxField label={translation.abnormal_setting_lsl} value={data?.abnormal_setting_lsl?.toString()} onValueChanged={(e) => valueChanged(e.value, 'abnormal_setting_lsl')} validationRules={validationOfText} />

                            {/* 2024-03-07 :: add ucl, lcl, remove abnormal_setting_status */}
                            <TextboxField label={translation.abnormal_setting_ucl} value={data?.abnormal_setting_ucl?.toString()} onValueChanged={(e) => valueChanged(e.value, 'abnormal_setting_ucl')} validationRules={validationOfText} />
                            <TextboxField label={translation.abnormal_setting_lcl} value={data?.abnormal_setting_lcl?.toString()} onValueChanged={(e) => valueChanged(e.value, 'abnormal_setting_lcl')} validationRules={validationOfText} />
                            {/* <DropdownField label={translation.abnormal_setting_status} value={data?.abnormal_setting_status} dataSource={abnormalStatusList} valueExpr="value" displayExpr="title" onValueChanged={(e) => valueChanged(e.value, 'abnormal_setting_status')} disabled={title === 'Add' ? false : true} /> */}

                            <DropdownField label={translation.use_or_not} value={data?.abnormal_setting_is_active} dataSource={useItems} valueExpr="value" displayExpr="title" onValueChanged={(e) => valueChanged(e.value, 'abnormal_setting_is_active')} />
                            <TextboxField label={translation.abnormal_setting_reason} value={data?.abnormal_setting_reason} onValueChanged={(e) => valueChanged(e.value, 'abnormal_setting_reason')} />
                            <TextboxField label={translation.abnormal_setting_recommend} value={data?.abnormal_setting_recommend} onValueChanged={(e) => valueChanged(e.value, 'abnormal_setting_recommend')} />
                        </div>
                        <div className="block"></div>
                        {title == 'Edit' && (
                            <>
                                <div className="dx-fieldset write-info">
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                        <div className="dx-field-value">{data?.modified_date}</div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                        <div className="dx-field-value">
                                            {data?.modified_by_name}
                                            {data?.modified_by_duty && <span className="badge badge-job-position">{data?.modified_by_duty}</span>}
                                            {data?.modified_by_department && <span className="badge badge-team">{data?.modified_by_department}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="block"></div>
                            </>
                        )}
                        <div>
                            <div className="btn-group dx-fieldset" style={{ width: '608px' }}>
                                <Button type="normal" stylingMode="text" className="btn-s-r" onClick={(_) => exitForm('/AbnormalSettings')}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button id="submitBtn" type="default" stylingMode="contained" className="btn-s-r" useSubmitBehavior={true}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <SelectRawDatasetPopup visible={isRawDatasetPopupVisible} onOk={rawDatasetSelected} onClose={() => setRawDatasetPopupVisibility(false)} selectedRawData={selectedRawData} />
            <SelectIdShortPopup rawDatasetId={data?.dataset_master_id} visible={isIdShortPopupVisible} onOk={idShortSelected} onClose={() => setIdShortsetPopupVisibility(false)} selectedIdShort={selectedIdShort} />

            {/* <AddColumnPopup popupTitle={translation.add_filter} type="filter" dataSource={filterDataSource} visible={addFilterPopup} onClose={() => setAddFilterPopup(false)} onSave={onAddFilterList} selectMode="multiple" selectedCol={dataFilterList} /> */}
            <AddColumnPopup popupTitle={translation.add_filter} type="filter" dataSource={filterDataSource} visible={isAddFilterPopup} onClose={() => setAddFilterPopup(false)} onSave={onAddFilterList} selectMode="multiple" selectedCol={filterList} />
        </div>
    );
}

const AddColumnPopup = ({ popupTitle = '', type, dataSource, visible, onClose = () => {}, onSave = () => {}, selectMode = 'multiple', selectedCol }) => {
    const translation = useTranslation();
    const [header, setHeader] = useState();
    const [okBtnDisabled, setOkBtnDisabled] = useState(true);
    const [selectedRows, setSelectedRows] = useState();
    const gridRef = useRef();
    const [gridData, setGridData] = useState();

    useEffect(() => {
        if (visible) {
            // const cDataGrid = JSON.parse(JSON.stringify(dataSource ? dataSource : []));
            const cDataGrid = dataSource;
            setGridData(cDataGrid);
            // set selected rows, and if type is filte or column then need pre-work
            let targetRow = [];
            if (selectedCol?.length > 0) {
                // targetRow = JSON.parse(JSON.stringify(selectedCol));
                targetRow = selectedCol;
                if (['filter', 'column'].includes(type)) {
                    const foundRows = cDataGrid.filter((data) => {
                        const same = targetRow.find((row) => row.collection_name === data.collection_name && row.column_name === data.column_name);
                        if (same != null) return data;
                    });
                    targetRow = foundRows;
                }
            }

            // const instance = gridRef.current?.getInstance();
            // instance?.deselectAll();
            // instance?.selectRows(targetRow);
            setSelectedRows(targetRow);

            // set header
            if (gridData?.length > 0) {
                const keys = Object.keys(gridData[0]).filter((key) => key !== 'title_json');
                const header = keys.map((key) => {
                    return { dataField: key };
                });
                setHeader(header);
            }
        }
    }, [visible]);

    useEffect(() => {
        const instance = gridRef.current?.getInstance();
        instance?.selectRows(selectedRows);
    }, [selectedRows]);

    const onRowSelected = (rows) => {
        // const instance = gridRef.current?.getInstance();
        if (rows == null) {
            // instance?.selectRows([]);
            setOkBtnDisabled(true);
            setSelectedRows([]);
        } else {
            // instance?.selectRows(Array.isArray(rows) ? rows : [rows]);
            setSelectedRows(Array.isArray(rows) ? rows : [rows]);
            setOkBtnDisabled(false);
        }
    };

    const removeTag = (i) => {
        let cur = [...selectedRows];
        let removed = cur.splice(i, 1);
        setSelectedRows(cur);

        const instance = gridRef.current.getInstance();
        instance.deselectRows(removed);
    };

    const removeAllTags = () => {
        const instance = gridRef.current.getInstance();
        // instance.deselectAll();
        instance.deselectRows(selectedRows);
        setSelectedRows([]);
    };

    const filtering = (keyword) => {
        const targetColumn = { Y: 'name', X: 'name', filter: 'column_name', dg_column: 'name', column: 'column_name' };
        let filtered = [];
        let lowKeyword = keyword?.toLowerCase();

        if (lowKeyword?.length > 0) filtered = dataSource.filter((data) => data[targetColumn[type]].toLowerCase().includes(lowKeyword));
        else filtered = dataSource;
        setGridData(filtered);
    };

    return (
        <Popup width={1100} height={850} visible={visible} hideOnOutsideClick={true} onHiding={onClose} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-add-yaxis">
                <div className="popup-header">
                    <p className="popup-title">{popupTitle}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            <ul className="selected-items">
                                {selectedRows?.map((e, i) => {
                                    const itemName = ['filter', 'column'].includes(type) ? e.column_name : e.name;
                                    return (
                                        <li key={i} className="item">
                                            <p className="item-name">{itemName}</p>
                                            <i className="material-icons btn-delete" onClick={() => removeTag(i)}>
                                                cancel
                                            </i>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="btn-all-delete" onClick={removeAllTags}>
                                <button>{translation.delete_all}</button>
                            </div>
                        </div>
                    </div>
                    <div className="item-select-box">
                        <ScrollView>
                            <div className="container-wrapper">
                                <div className="container">
                                    <BaseDataGrid ref={gridRef} toolbarModules={[visible && <FilterComponent label={translation.filter} onFilter={filtering} width={204} />]} headers={header} dataSource={gridData} allowModify={false} onSelectionChanged={onRowSelected} selectMode={selectMode} />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={onClose} />
                    <Button text={translation.save} width={86} type="default" stylingMode="contained" className="btn-s-r" disabled={okBtnDisabled} onClick={() => onSave(selectedRows)} />
                </div>
            </div>
        </Popup>
    );
};
