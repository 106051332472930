import { Popup } from 'devextreme-react';

export default {
    id: 'ID',
    password: 'Password',
    setting: 'Setting',
    homePara: "Language localisation is the process of adapting a product's translation to a specific country or region. It is the second phase of a larger process of product translation and cultural adaptation to account for differences in distinct markets, a process known as internationalisation and localisation.",
    aboutPara:
        "This tutorial is targetted to make localization simple. Though there are a lot of tutorials available on the internet. But, this one will teach you the concept in a simple manner. You can directly use this code for your software. I have used 'Rreact' hooks which is software industry standard now. You can follow my blog to get more UI related articles which will make you a better 'UI' developer. {0}",
    changeLanguage: 'Change your langauge',
    name: 'Name',
    age: 'Age',
    address: 'Address',
    address1: 'Address',
    address2: 'Address(Detail)',
    search: 'Search',
    filter: 'Filter',
    dateField: 'Date',
    contactNo: 'Contact No',
    save: 'Save',
    formPara: 'Please, enter your personal details here.',
    dynamicValue: 'Dynamic Value- {randomNumber} {date}',
    email: 'email',
    signIn: 'Sign In',
    signIn_title: 'SIGN IN',
    signUp: 'Sign Up',
    forgotPassword: 'Forgot Password?',
    account: 'Account',
    companyName: 'Company name',
    prev: 'prev',
    next: 'next',
    companyRegNo: 'Company reg No.',
    ceo: 'CEO',
    companyLogo: 'Company logo',
    dashboard: 'Dashbaord',
    dashboardCompanyInfo: 'Information',
    dashbaordAddress: 'Address',
    startDate: 'Start',
    updateDate: 'Update',
    dashboardUsageStatistics: 'Usage Statistics',
    dashboardBusiness: 'Business',
    dashboardDepartment: 'Department',
    dashboardMembers: 'Members',
    dashboardAIModel: 'AI Model',
    dashboardConnectedAI: 'Connected AI Service',
    logoDesc: 'Browse to find or drag image here. (JPG, PNG)',
    multipleLogoDesc: 'Browse to find or drag image here \n (JPG, PNG, BMP)',
    findPassword: 'Find Password',
    forgotPasswordDesc: '<p><span>A temporary password</span><br/>will be sent to <span>the email</span><br/> you entered during registration</p>',
    confirm: 'OK',
    cancel: 'Cancel',
    apply: 'Apply',
    companyInfo: 'Company Info',
    comment: 'Comment',
    confirm_signIn: 'you have signed in.',
    confirm_signUp: 'You have signed up.',
    send_signUp_message: 'will receive an email.',
    request_error: 'The error has occurred while processing your request.',
    signIn_failed: 'Wrong Email or password',
    business: 'Business',
    indivisual: 'Individual',
    finish: 'finish',
    alertSignOut: 'Do you really want to sign out?',
    serviceIndivisual: 'Service for individual member',
    serviceBusiness: 'Service for company member',
    lastModifiedDate: 'Last modified date',
    lastModifiedBy: 'Last modified by',
    seniorResearcher: 'Senior Researcher',
    uiux: 'UI/UX TEAM',
    use: 'Use',
    notUse: 'Not use',
    use_or_not: 'Use or not',
    work: 'Work',
    leave: 'Leave',
    moveRow: 'move rows',
    delete: 'Delete',
    edit: 'Edit',
    add: 'Add',
    copy: 'Copy',
    undo: 'undo',
    redo: 'redo',
    template_category: 'Template category',
    department_management: 'Department Management',
    user_management: 'User Management',
    operation_factory: 'Operation Factory',
    operation_process: 'Operation Process',
    operation_line: 'Operation Line',
    product_group: 'Product Group',
    myPage: 'My Page',
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    logOut: 'Log out',
    addApp: 'Apps',
    attachment: 'Attachment',
    addUser: 'ADD USERS',
    addGroup: 'ADD GROUPS',
    active: 'Use',
    not_active: 'Not use',
    not_available: 'not available',
    hierarchy: 'Hierarchy',
    menu_name: 'Menu Name',
    language: 'Language',
    add_filter: 'Add filter',
    template: 'Template',
    template_name: 'Template name',
    date: 'Date',
    real_time: 'Real time',
    dropdown: 'Drop Down',
    button: 'Button',
    toggle: 'Toggle',
    check_box: 'Check Box',
    radio_button: 'Radio Button',
    select_component: 'Please select component.',
    user: 'User',
    dept: 'Department',
    comingSoon: 'Coming soon',
    favorites: 'Favorites',
    reportPrint: 'Report Print',
    basic: 'Basic',
    no: 'NO',
    no_data: 'No Data',
    no_data_comment: 'Please select another item',
    _business: 'Business',
    state: 'state',
    mold: 'Mold',
    add_mold: 'Add mold',
    period: 'Period',
    item: 'Item',
    add_item: 'Add item',
    delete_all: 'Delete All',
    close: 'Close',
    mainportal_title1: 'The challenge of endless possibilities',
    mainportal_title2: 'Increase business productivity with manufacturing big data analysis and AI application with InterX',
    all: 'All',
    no_page_available: 'The page has been deleted.',

    //chart
    line_chart: 'Line Chart',
    bar_chart: 'Bar Chart',
    scatter_chart: 'Scatter Chart',
    polygonChart: 'Polygon Chart',
    treeChart: 'Tree Chart',
    liveChart: 'Live Chart',
    pie_chart: 'Pie Chart',
    radar_chart: 'Radar Chart',
    chart: 'Chart',

    //form validation
    required_id: 'ID is requried.',
    required_pw: 'Password is required.',
    required_currentPassword: 'Current password is required.',
    required_newPassword: 'New password is required.',
    required_confirmPassword: 'Confirm password is required.',
    invalid_new_confirm_pwd: 'New password and Confirm password are not the same.',
    invalid_current_new_pwd: 'Current password and New password are the same.',
    invalid_currnet_pwd: 'Current password is wrong.',
    required_email: 'Email is required.',
    required_companyName: 'Company name is required.',
    required_companyRegNo: 'Company registration number is required.',
    required_ceo: 'CEO is required.',
    required_phoneNo: 'Phone number is required.',
    required_name: 'Name is required',
    invalid_email: 'Email is invalid.',
    invalid_phoneNo: 'Phone number is invalid.',
    invalid_date: 'Value is not a valid date type.',
    hyphenNotAllowed: ' - is not allowed.',
    required_businessName: 'Business name is required.',
    required_departmentName: 'Department name is required.',
    required_badTypeCode: 'Bad type code is required',
    required_badTypeName: 'Bad type name is required',
    required_DivisionSubValue: 'Division value is required',
    required_useOrNot: 'Use or not is required.',
    required_duty: 'Duty is required.',
    required_hired_date: 'Hired date is required.',
    required_is_work: 'Work / Leave is required',
    required_factory_code: 'Factory code is required.',
    required_process_code: 'Process code is required.',
    required_product_group_code: 'Product group code is required.',
    required_item_category_code: 'Category code is required.',
    required_line_code: 'Line code is required.',
    required_templateName: 'Template name is required.',
    required_value: 'Value is required',
    request_delete: 'Do you really want to delete?',
    request_save: 'Do you want to save?',
    save_failed: 'Save failed.',
    save_successfully: 'Save Sucessfully.',
    update_successfully: 'Edit Sucessfully.',
    is_required: ' is required.',
    is_invalid: ' is invalid.',
    aas_file: 'AASX File',
    is_reset_reload_alert: 'The input value is initialized. Do you want to reload?',

    // business
    select_business: 'Select business',
    user_business_no: 'NO',
    user_business_id: 'Business id',
    user_business_name: 'Business name',
    user_business_zipcode: 'Zipcode',
    user_business_address: 'Address',
    user_business_address2: 'Address 2',
    user_business_phone: 'Business contact',
    user_business_is_active: 'Use or not',
    user_business_comment: 'Comment',
    user_business_modified_by: 'Last modified by',
    user_business_modified_date: 'Last modified date',
    user_business_created_by: 'Created by',
    user_business_created_date: 'Created date',
    user_business_save: 'Save Successfully',

    // department
    user_department_id: 'Department id',
    user_department_parent_id: 'Department parent id',
    user_department_path: 'Department path',
    user_department_seq: 'Sequence',
    user_department_name: 'Department name',
    user_department_is_active: 'Use or not',
    user_department_comment: 'Comment',
    selected_department: 'Selected department',

    // user
    user_master_id: 'User id',
    user_department_id: 'Department id',
    user_master_email: 'User email',
    user_master_name: 'User name',
    user_master_duty: 'User duty',
    user_master_position: 'User position',
    user_master_is_active: 'State',
    user_master_comment: 'Comment',
    user_master_phone: 'User phone',
    user_master_hired_date: 'Join date',
    user_master_is_work: 'Work / Leave',
    user_master_work_state: 'State',
    user_master_is_select_department: 'Please, select department',
    user_master_hired_work: 'Work',
    user_master_hired_leave: 'Leave',

    // Security_group
    security_master_id: 'Security master id',
    security_master_name: 'Security name',
    security_master_is_active: 'Use or not',
    security_master_comment: 'Comment',
    security_master_duplicated: 'Security name is existed.',
    security_master_target_no: 'No',
    security_master_target_name: 'User',
    security_master_target_id: 'Id',
    security_master_target_table: 'DIVISION',
    security_master_notice: 'You can add a target with the Search or Add User button.',
    security_group: 'Security group',
    security_group_business: 'BUSINESS',
    security_group_department: 'DEPARTMENT',
    security_group_user: 'USER',

    // Use History
    sys_log_history_no: 'No',
    user_master_id: 'User id',
    user_master_name: 'User',
    sys_log_datetime: 'Use time',
    user_log_ip: 'IP',
    sys_log_action: 'Action',
    sys_log_result: 'Result',
    sys_log_comment: 'Comment',
    sys_menu_mapping: 'Use menu',

    // product group
    select_product_group: 'Select product group',
    product_group_id: 'Product group id ',
    user_business_name: 'Business name',
    product_group_code: 'Product group code',
    product_group_name: 'Product group name',
    product_group_is_active: 'Use or not',
    product_group_comment: 'Comment',
    no_security_selected: 'No security group is selected.',

    // operation factory
    _factory: 'Factory',
    select_factory: 'Select factory',
    operation_factory_id: 'Factory id ',
    user_business_name: 'Business name',
    operation_factory_code: 'Factory code',
    operation_factory_name: 'Factory name',
    operation_factory_is_active: 'Use or not',
    operation_factory_comment: 'Comment',

    // Item Category
    itemCategory: 'Item category',
    item_category_template: 'Item template',
    item_category_id: 'Category id',
    item_category_parent_id: 'Parent id',
    item_category_path: 'Category path',
    item_category_name: 'Category name',
    item_category_code: 'Category code',
    item_category_is_active: 'Use or not',
    item_category_comment: 'Comment',
    propagateToParent: 'Apply upper nodes',
    propagateToChildren: 'Apply lower nodes',

    // Item Master
    itemMaster: 'Item master',
    item_master_no: 'No',
    item_master_id: 'Item id',
    item_master_name: 'Item name',
    item_master_code: 'Item code',
    product_group: 'Product group',
    item_master_eo_no: 'Eo No.',
    item_master_eo_date: 'Eo date',
    item_master_customer: 'Customer',
    item_master_material: 'Material',
    item_master_size: 'Scale(width*depth*height)',
    item_master_weight: 'Weight(kg)',
    item_master_is_active: 'Use or not',
    item_master_comment: 'Comment',
    productClassification: 'Product classification',
    item_classification: 'Item Classification',

    // operation process
    operation_process_id: 'Process id ',
    user_business_name: 'Business name',
    operation_process_code: 'Process code',
    operation_process_name: 'Process name',
    operation_process_is_active: 'Use or not',
    operation_process_comment: 'Comment',
    operation_process_seq: 'Process seq',
    operation_process_equipment: 'Process equipment',
    equipment_management: 'Equipment management',

    //operation line
    operation_line_id: 'Line id',
    operation_line_code: 'Line code',
    operation_line_name: 'Line name',
    operation_line_is_active: 'Use or not',
    operation_line_comment: 'Comment',
    add_process: 'Add Process',
    process_management: 'Process management',
    process: 'Process',
    select_process: 'Select Process',
    line: 'Line',

    // quality  badtype
    quality_badtype_no: 'NO',
    quality_badtype_id: 'Bad type id',
    quality_badtype_code: 'Bad type code',
    quality_badtype_name: 'Bad type name',
    quality_badtype_is_active: 'Use or not',
    quality_badtype_comment: 'Comment',
    quality_badtype_active: 'Use',
    quality_badtype_not_active: 'Not use',
    quality_badtype_select_item: 'Select item',
    quality_badtype_item_name: 'Item name',
    quality_badtype_item_code: 'Item code',
    quality_badtype_product_group: 'Product group',

    // inspection defect type
    defect_type_no: 'NO',
    inspection_type: 'Inspection type',
    inspection_type_name: 'Inspection type',
    defect_type_id: 'Defect type id',
    defect_type_code: 'Defect type code',
    defect_type_name: 'Defect type name',
    defect_type_example_img: 'Defect type example',
    defect_type_is_active: 'Status',
    defect_type_comment: 'Comment',
    select_inspectionType: 'Inspection type',
    applied_item: 'Applied item',

    // inspection standard group
    inspection_library_no: 'NO',
    inspection_library_id: 'Inspection library id',
    inspection_library_type: 'Inspection library type',
    inspection_library_code: 'Inspction library code',
    inspection_library_name: 'Inspection library name',
    inspection_library_is_active: 'Status',
    inspection_library_comment: 'Comment',
    inspection_library_copied_code: 'Inspection library code to be copied',
    inspection_library_copied_name: 'Inspection library name to be copied',
    item_inspection_code: 'Inspection report code',
    item_inspection_name: 'Inspection report name',
    item_inspection_comment: 'Inspection report comment',

    // inspection Library Condition
    inspection_library_defect_type_id: 'Defect type id',
    inspection_library_defect_type_code: 'Defect type code',
    inspection_library_defect_type_name: 'Defect type name',
    inspection_library_defect_type_formula: 'Measure formula',
    inspection_library_defect_type_is_active: 'Status',
    inspection_library_defect_type_comment: 'Comment',
    inspection_library_condition: 'Inspection condition',
    inspection_library_condition_value: 'Inspection condition value',
    inspection_library_condition_direction: 'Inspection condition direction',
    inspection_library_condition_gap_plus: 'Inspection condition gap plus',
    inspection_library_condition_gap_minus: 'Inspection condition gap minus',
    inspection_library_condition_unit: 'Inspection condition unit',
    add_inspection_library: 'add inspection library',
    add_defect_type: 'add defect type',

    load_defect_type: 'Load defect type',
    load_inspection_standard: 'Load inspection standard',
    add_condition: 'Add condition',

    no_measure_condition: 'No measure condition',
    no_measure_condition_comment: 'Please add measure condition',

    // machine_equipment
    _equipment: 'Equipment',
    select_equipment: 'Select equipment',
    machine_equipment_id: 'Equipment id',
    operation_factory_name: 'Factory name',
    machine_equipment_type: 'Equipment type',
    machine_equipment_code: 'Equipment code',
    machine_equipment_name: 'Equipment name',
    machine_equipment_made: 'Production company',
    machine_equipment_supplier: 'Supplier',
    machine_equipment_modelno: 'Model no',
    machine_equipment_date: 'Installation date',
    machine_equipment_is_active: 'Use or not',
    machine_equipment_comment: 'Comment',
    machine_equipment_logo: 'Equipment logo',
    machine_equipment_duplicated: 'Duplicates exist',
    machine_equipment_images: 'Equipment images',
    add_equipment: 'Add equipment',

    // machine_mold
    select_items: 'Select items',
    selected: 'Selected',
    machine_mold_id: 'Mold id',
    operation_factory_name: 'Factory name',
    machine_mold_code: 'Mold code',
    machine_mold_name: 'Mold name',
    machine_mold_no: 'Mold no',
    machine_mold_no1: 'No',
    product_item_code: 'Item code',
    product_item_name: 'Item name',
    machine_mold_org: 'Department to manage',
    machine_mold_is_active: 'Use or not',
    machine_mold_comment: 'Comment',
    machine_mold_images: 'Mold images',
    machine_no_item: 'Item is not exist. Please add item in item menu.',

    //Division Management
    sys_division_id: 'Division id',
    sys_division_code: 'Didivion code',
    sys_division_name: 'Division name',
    sys_division_is_active: 'State',
    sys_division_can_edit: 'Can edit',
    sys_division_edit: 'UNLOCK',
    sys_division_not_edit: 'LOCK',
    sys_division_comment: 'Comment',
    sys_division_sub_parent_id: 'Division parent id',
    sys_division_sub_lang_code: 'Language',
    sys_division_sub_caption_text: 'Caption',
    sys_division_sub_value: 'Division value',
    sys_division_sub_seq: 'Sequence',
    sys_division_sub_is_active: 'State',
    sys_division_sub_active: 'Use',
    sys_division_sub_not_active: 'Not use',
    sys_division_sub_default_check: 'Default check',
    sys_division_sub_comment: 'Comment',
    sys_division_use: 'Use',
    sys_division_not_use: 'Not use',
    sys_division_select_division: 'Please select Division',

    //Template Master
    template_path: 'Template path',
    my_tempalte: 'My template',
    template_store: 'Template store',
    template_classification: 'Template classification',
    select_template: 'Select template',
    template_master_name: 'Template name',
    template_master_is_active: 'Use or not',
    template_master_comment: 'Comment',
    template_master_icon_registor: 'Template icon reg',
    template_master_basic_theme: 'basic theme',
    template_master_dark_theme: 'dark theme',
    template_master_icon: 'icon',
    templateDesc: 'Drag and drop image here or click here and select file. (JPG, PNG, SVG / 120X120 size recommend)',
    template_no_data: 'No Template.',
    template_no_data_comment: 'Please select different category or add your template.',
    builder_option: 'Builder option',
    template_name: 'Template name',
    add_row: 'Add row',
    add_col: 'Add column',
    layout_generator: 'Layout generator',
    template_builder: 'Template builder',
    template_info_add: 'is a parent item.',
    exist_template_master_in_menu_mapping: 'Template master exists in menu mapping. Can not edit.',
    preview: 'preview',
    no_template_classification: 'Template classification is not exist.<br>Please add',

    //custom success messages
    success_forgot_pwd: 'password has been sent to your registered email.',
    success_insert: 'Successfully inserted',
    success_update: 'Successfully updated',
    success_remove: 'Successfully removed',
    success_change_pwd: 'Successfully changed',
    success_update_seq: 'Successfully changed',
    success_delete_row: 'Successfully deleted',
    success_add_shortcut: 'This page has been turned on for favorites',
    success_delete_shortcut: 'This page has been turned off for favorites',

    //custom alert message
    alert_delte: 'Are you sure you want to remove?',
    confirm_delete: 'Are you sure you want to delete?',
    confirm_recursive_delete: 'Are you sure you want to delete the selected row and sub-row?',
    alert_remove_default_menu: "You can't delete system menu",

    //info
    select_mold: 'Select a mold',
    select_item: 'Select a item',
    info_binding_date: 'Check the data on the page by selecting it based on a specific date.<br />You can choose two types: Date Picker and Data Range.',
    select_box_from_canvas: 'Please select a box<br /> from the canvas on the right.',
    select_a_row_to_edit: 'You must select at least a row to edit.',
    search_business_group: 'Search business group',
    search_and_select_departments: 'Search and select departments',
    search_for_names_or_groups: 'Search for names or groups',
    search_for_names_or_email: 'Search for names or e-mail',
    search_and_select_user: 'Search and select users',
    search_and_select_group: 'Search and select groups',
    info_grant_permission: 'Please select users to grant permission',
    info_grant_group: 'Please select group to grant permission',
    info_select_box: 'Please select a box\n from the canvas on the right',
    info_add_group: 'You can add groups with ADD GROUPS button.',
    info_search_name_group: 'Search for names or groups',
    info_add_product_group: 'Please select product group to add.',
    info_menu_lang: 'Menu names depends on language selection.',
    info_process_management: 'You can add a targets with ADD PROCESS button. ',
    info_equipment_management: 'You can add targets with ADD EQUIPMENT button.',
    create_at_least_one_row: 'Please create at least one row',
    selected_items: 'Selected items',
    info_add_process: 'Please select process to add.',
    only_reordering: 'Only reordering is allowed.',

    //menu
    select_service: 'Select service',
    menu_path: 'Menu path',
    menu_mapping_name: 'Menu name',
    menu_mapping_comment: 'Comment',
    permission_setting: 'Permission setting',
    menu_security_notice: 'You can add a target with ADD GROUPS button.',
    binding_data: 'Binding data',
    create: 'Create',
    update: 'Update',
    delete: 'Delete',
    selected_template: 'Selected template',
    card_option: 'Card option',
    card_title: 'Card title',
    data_setting: 'Data setting',
    filter_config: 'Config',
    title: 'Title',
    set_interval: 'Set interval (sec)',
    date_time: 'Date / Time',
    plz_data_setting: 'Please Data Setting',
    add_data: 'Add data',
    select_dataset: 'Select dataset',
    alert_change_dropdown: 'When you change the dropdown item, previous settings for selected card will be cleared.',
    dropdown: 'Drop Down',
    plz_select_comp: 'Please select a component',
    type: 'Type',
    option: 'Option',
    check: 'Check',
    specify_date: 'Check the data on the page by selecting it based on a specific date.<br />You can choose two types: Date Picker and Data Range.',
    min_value_5: 'value can not be less then 5 seconds.',
    component_setting: 'Component Setting',
    select_component_setting: 'Please select a component to setting',
    component: 'Component',
    component_title: 'Title',
    component_type: 'Component Type',
    drag_to_delete: 'Drag elemets here to delete',
    binding_state: 'Binding State',
    add_new_item_category: 'Add new Item category',
    select_chart: 'Select chart',

    add_page_filter: 'Add page filter',
    add_new_chart_type: 'Add new chart type',
    chart_type: 'Chart type',
    view_data_sample: 'View data sample',
    chart_data_filter: 'Chart data filter',
    add_filter: 'Add filter',
    y_axis: 'Y axis',
    x_axis: 'X axis',
    add_y_axis: 'Add Y axis',
    add_x_axis: 'Add X axis',
    add_dg_columns: 'Add DataGrid columns',
    binding_column: 'Binding column',
    add_binding_column: 'Add Binding Column',
    /* new options */
    min: 'min',
    max: 'max',
    avg: 'avg',
    /* new options */
    default_period: 'Default period',
    alert_reset_filter: 'Page filter settings may be reset. Are you sure you want to change the raw dataset?',
    alert_need_more_page_filter_setting: 'Page filters must be filled correctly',
    grid_setting: 'Grid Settings',
    use_numbering: 'Numbering',
    header_styling: 'Header Styling',
    body_styling: 'Body Styling',
    styling: 'Styling',
    font_style: 'Font Style',
    align: 'Align',
    vertical: 'Vertical',
    font_color: 'Font Color',
    background_color: 'Background',
    global: 'Global',
    pagination_size: 'Pagination Size',
    raw_data_filter: 'Raw Data Filter',
    add_columns: 'Add Columns',
    columns: 'Columns',
    width: 'Width',
    show: 'Show',
    link_option: 'Link Option',
    add_link: 'Add Link',
    link: 'Link',
    link_open: 'Link Open',
    link_open_page: 'page',
    link_open_popup: 'popup',
    link_name: 'Link Name',
    english: 'English',
    korean: 'Korean',
    parameter: 'Paramenter',
    time: 'Time',

    //Dataset Management
    dataset_name: 'Dataset name',
    table: 'Table',
    column: 'Column',
    aas_path: 'AAS path',
    no_aas_selected: 'No AAS is selected.',
    add_dataset: 'ADD DATASET',
    dataset_setting: 'Dataset Setting',
    dataset_table: 'Dataset Table',

    //Master Dataset
    master_dataset_code: 'Master dataset code',
    master_dataset_name: 'Master dataset name',
    master_dataset_start: 'dataset start',
    master_dataset_end: 'dataset end',
    master_dataset_comment: 'comment',
    raw_dataset_name: 'raw dataset name',
    raw_dataset: 'Raw dataset',
    master_dataset_type: 'Master dataset type',
    item_image: 'Item image',

    //Abnormal settings
    abnormal_setting_no: 'NO',
    abnormal_setting_type: 'Type',
    id_short: 'ID short',
    abnormal_setting_usl: 'USL',
    abnormal_setting_lsl: 'LSL',
    abnormal_setting_ucl: 'UCL',
    abnormal_setting_lcl: 'LCL',
    abnormal_setting_status: 'Status',
    abnormal_setting_reason: 'Reason',
    abnormal_setting_recommend: 'Comment',
    abnormal_setting_factory: 'Factory',
    abnormal_setting_line: 'Line',
    abnormal_setting_process: 'Process',
    abnormal_setting_machine: 'machine',
    abnormal_setting_item:"Item",
    select_setting_type: 'Select setting type',
    heater_temperature: 'Heater temperature',

    // Abnormal settings
    abnormal_sign_log_no: 'NO',
    abnormal_sign_log_status: 'Status',
    abnormal_sign_log_reason: 'Reason',
    abnormal_sign_log_recommend: 'Comment',
    abnormal_sign_log_created_date: 'Create datetime',
    date_range: 'Date range',
    log_action: 'Action',
    log_abnormal: 'Abnormal',
    checkedDateTime: 'Checked datetime',
    abnormal_log: 'Log',

    //popup
    select_item: 'Select item',
    select_item_notice: 'Please, select item',

    select_line: 'Select Line',

    select_department: 'Select department',
    select_department_notice: 'Please, select department',

    select_product_group: 'Select product group',
    select_product_group_notice: 'Please, select product group',

    select_aas: 'Select AAS',
    select_aas_notice: 'Please, select AAS',

    select_equipment: 'Select equipment',
    select_equipment_notice: 'Please, select equipment',

    select_department_and_user: 'Select department and user',
    select_department_and_user_notice: 'Please, select department and user',

    select_process: 'Select process',
    select_process_notice: 'Please, select process',

    select_raw_dataset: 'Select raw dataset',
    select_raw_dataset_notice: 'Please, select raw dataset',

    select_mold: 'Select mold',
    select_mold_notice: 'Please, select mold',

    select_security_group: 'Select security group',
    select_security_group_notice: 'Please, select security group',

    select_process_and_line: 'Select process and line',
    select_process_and_line_notice: 'Please, select process and line',

    select_master_dataset: 'Select master dataset',
    select_master_dataset_notice: 'Please, master dataset',

    select_user: 'Select user',
    select_user_notice: 'Please, select user',

    multi_chart_setting: 'Multi chart settings',
    add_chart: 'add chart',

    select_id_short: 'Select id short',
    select_id_short_notice: 'Please, select id short',

    //codes for data response
    MSG_94: 'AASX file reader API not responding.',
    MSG_96: 'data is bad type. choose different option.',
    MSG_97: 'empty data in database',
    MSG_98: 'please enter correct email address.',
    MSG_99: 'error while processing your request.',
    MSG_100: 'success',
    MSG_101: 'user email already exists.',
    MSG_102: 'company code already exists.',
    MSG_103: 'error',
    MSG_104: 'the item category name already exists within the parent item category.',
    MSG_105: 'item name already exists.',
    MSG_106: 'item code already exists.',
    MSG_107: 'bad type code already exists.',
    MSG_108: 'bad type name already exists.',
    MSG_109: 'equipment name already exists.',
    MSG_110: 'equipment code already exists.',
    MSG_111: 'factory name already exists.',
    MSG_112: 'factory code already exists.',
    MSG_113: 'line name already exists.',
    MSG_114: 'line code already exists.',
    MSG_115: 'mold name already exists.',
    MSG_116: 'mold code already exists.',
    MSG_117: 'process name already exists.',
    MSG_118: 'process code already exists.',
    MSG_119: 'product group name already exists.',
    MSG_120: 'product group code already exists.',
    MSG_121: 'security name already exists.',
    MSG_122: 'can not edit.',
    MSG_123: 'division value already exists.',
    MSG_124: 'business name already exists.',
    MSG_125: 'company code already exists.',
    MSG_126: 'department name already exists.',
    MSG_127: 'not exists.',
    MSG_128: 'exists.',
    MSG_129: 'template category name already exists.',
    MSG_130: 'template master with same name already exists.',
    MSG_131: 'system menu cannot be deleted.',
    MSG_132: 'system security group cannot be deleted.',
    MSG_133: 'system template category cannot be deleted.',
    MSG_134: 'system template master cannot be deleted.',
    MSG_135: 'menu name already exists.',
    MSG_136: 'You cannot add a 4th level menu.',
    MSG_137: 'It cannot be deleted.',
    MSG_138: 'AAS package name already exists.',
    MSG_139: 'dataset name already exists.',
    MSG_140: 'master dataset name already exists.',
    MSG_141: 'The same master dataset already exists',
    MSG_142: 'Inspection defect type name already exists.',
    MSG_143: 'Inspection defect type code already exists.',
    MSG_144: 'Inspection library name already exists.',
    MSG_145: 'Inspection library code already exists.',
    MSG_146: 'This defect type already exists in the inspection library.',
    MSG_147: 'Inspection standards code already exists.',
    MSG_148: 'Inspection standards name already exists.',
    MSG_149: 'defect type category name already exists.',
    MSG_150: 'Abnormal setting already exists.',
    MSG_151: 'This email is for another company.',
    MSG_152: 'A folder with the same name exists and cannot be moved to the same path.',
    MSG_160: 'Kindly eliminate any dependencies from the following items',
    MSG_163: 'It cannot be deleted. ',

    after_business_add: 'You can register a factory within a business, so please register a business first.',
};
