export default {
    id: '아이디',
    password: '비밀번호',
    setting: '셋팅',
    homePara:
        "La localisation linguistique est le processus d'adaptation de la traduction d'un produit à un pays ou une région spécifique. C'est la deuxième phase d'un processus plus large de traduction de produits et d'adaptation culturelle pour tenir compte des différences entre les marchés distincts, un processus connu sous le nom d'internationalisation et de localisation",
    aboutPara:
        "Ce didacticiel vise à simplifier la localisation. Bien qu'il existe de nombreux tutoriels disponibles sur Internet. Mais celui-ci vous apprendra le concept de manière simple. Vous pouvez directement utiliser ce code pour votre logiciel. J'ai utilisé des crochets «Rreact» qui sont maintenant la norme de l'industrie du logiciel. Vous pouvez suivre mon blog pour obtenir plus d'articles liés à l'interface utilisateur, ce qui fera de vous un meilleur développeur «UI». {0}",
    changeLanguage: '언어변경',
    name: '이름',
    age: '나이',
    address: '주소',
    address1: '주소',
    address2: '상세주소',
    search: '검색',
    filter: '필터',
    dateField: '날짜조회',
    contactNo: '연락처',
    save: '저장',
    formPara: '개인정보를 입력해주세요.',
    dynamicValue: 'Dynamic Value- {randomNumber} {date}',
    email: '이메일',
    signIn: '로그인',
    signIn_title: '로그인',
    signUp: '회원가입',
    forgotPassword: '비밀번호를 잊으셨나요?',
    account: '계정',
    companyName: '회사명',
    prev: '이전',
    next: '다음',
    companyRegNo: '사업자등록번호',
    ceo: '대표자',
    companyLogo: '기업로고',
    comment: '설명',
    logoDesc: '여기에 원하는 파일을 드래그 앤 드롭 하거나 클릭하여 파일을 선택할 수 있습니다. (JPG, PNG)',
    dashboard: '대시보드',
    dashboardCompanyInfo: '회사정보',
    dashbaordAddress: '사업부소재지',
    startDate: '시작 날짜',
    updateDate: '업데이트 날짜',
    dashboardUsageStatistics: '사용현황',
    dashboardBusiness: '사업부',
    dashboardDepartment: '부서',
    dashboardMembers: '사용자',
    dashboardAIModel: 'AI 모델',
    dashboardConnectedAI: '연결된 AI 서비스',
    multipleLogoDesc: '클릭하여 파일을 선택하거나 파일을 끌어 넣어주세요. (JPG, PNG, BMP)',
    findPassword: '비밀번호 찾기',
    forgotPasswordDesc: `<p>가입시 입력한 <span>이메일</span>로<br /><span>임시 패스워드</span>가 전송됩니다.</p>`,
    confirm: '확인',
    cancel: '취소',
    apply: '적용',
    companyInfo: '회사설명',
    confirm_signIn: '로그인 되었습니다.',
    confirm_signUp: '회원가입이 성공했습니다.',
    send_signUp_message: '으로 메일이 전송되었습니다.',
    request_error: '요청을 처리하던 중 에러가 발생했습니다.',
    business: '기업',
    indivisual: '개인',
    finish: '완료',
    signIn_failed: '이메일 또는 비밀번호가 맞지 않습니다.',
    alertSignOut: '정말로 로그아웃하시겠습니까?',
    serviceIndivisual: '개인회원을 위한 서비스',
    serviceBusiness: '기업회원을 위한 서비스',
    lastModifiedDate: '수정일',
    lastModifiedBy: '수정자',
    seniorResearcher: '책임연구원',
    uiux: 'UI/UX 팀',
    use: '사용함',
    notUse: '사용안함',
    use_or_not: '사용여부',
    work: '재직',
    leave: '퇴사',
    moveRow: '행 이동하기',
    delete: '삭제',
    edit: '수정',
    add: '추가',
    copy: '복사',
    undo: '뒤로',
    redo: '앞으로',
    template_category: '분류',
    department_management: '부서관리',
    user_management: '사용자관리',
    operation_factory: '공장관리',
    operation_process: '공정관리',
    operation_line: '라인관리',
    myPage: '마이페이지',
    changePassword: '비밀번호 변경',
    currentPassword: '현재 비밀번호',
    newPassword: '새 비밀번호',
    confirmPassword: '새 비밀번호 확인',
    logOut: '로그아웃',
    addApp: '앱추가',
    attachment: '첨부파일',
    addUser: '사용자 추가',
    addGroup: '그룹 추가',
    active: '사용함',
    not_active: '사용안함',
    not_available: 'not available',
    hierarchy: '상위 분류',
    menu_name: '메뉴명',
    language: '언어',
    add_filter: '필터 추가',
    card_option: '카드 옵션',
    card_title: '카드 제목',
    template: '템플릿',
    template_name: '템플릿 이름',
    date: '날짜',
    real_time: '실시간',
    dropdown: '드롭다운',
    button: '버튼',
    toggle: '토글',
    check_box: '체크박스',
    radio_button: '라디오 버튼',
    select_component: '컴포넌트를 선택해주세요.',
    user: '사용자',
    dept: '부서',
    comingSoon: '준비중입니다.',
    favorites: '즐겨찾기',
    reportPrint: '리포트 인쇄',
    basic: '기본',
    no: 'NO',
    no_data: '데이터 없음',
    no_data_comment: '다른 항목을 선택해주세요.',
    _business: '사업부',
    state: '상태',
    mold: '몰드',
    add_mold: '몰드추가',
    period: '기간',
    item: '제품',
    add_item: '제품 추가',
    delete_all: '전체 삭제',
    close: '닫기',
    mainportal_title1: '끝없는 가능성에 대한 도전',
    mainportal_title2: '인터엑스와 함께 제조 빅데이터 분석과 AI 적용으로 비즈니스 생산성을 향상시키세요',
    all: '전체',
    no_page_available: '삭제된 페이지 입니다.',

    //chart
    line_chart: '선도표',
    bar_chart: '막대 그래프',
    scatter_chart: '산점도 그래프',
    polygonChart: '폴리곤 차트',
    treeChart: '트리 차트',
    liveChart: '실시간 차트',
    pie_chart: '원 그래프',
    radar_chart: '방사형 그래프',
    chart: '차트',

    //form validation
    required_id: '아이디를 입력해주세요.',
    required_pw: '비밀번호를 입력해주세요.',
    required_currentPassword: '현재 비밀번호를 입력해주세요.',
    required_newPassword: '새 비밀번호를 입력해주세요.',
    required_confirmPassword: '새 비밀번호 확인을 입력해주세요.',
    invalid_new_confirm_pwd: '새 비밀번호와 비밀번호 확인이 일치하지 않습니다.',
    invalid_current_new_pwd: '현재 비밀번호와 새 비밀번호가 동일합니다.',
    invalid_currnet_pwd: '현재 비밀번호를 정확하게 입력해주세요.',
    required_email: '이메일을 입력해주세요.',
    required_companyName: '회사명을 입력해주세요.',
    required_companyRegNo: '사업자등록번호를 입력해주세요.',
    required_ceo: '대표자명을 입력해주세요.',
    required_phoneNo: '전화번호를 입력해주세요.',
    required_name: '이름을 입력해주세요.',
    invalid_email: '유효하지 않은 이메일입니다.',
    invalid_phoneNo: '유효하지 않은 전화번호입니다.',
    invalid_date: '유효하지 않은 날짜형식입니다.',
    hyphenNotAllowed: ' - 을 사용하실 수 없습니다.',
    required_businessName: '사업부명을 입력해주세요.',
    required_departmentName: '부서명을 입력해주세요.',
    required_badTypeCode: '불량유형코드를 입력해주세요.',
    required_badTypeName: '불량유형명을 입력해주세요.',
    required_DivisionSubValue: '항목값을 입력해주세요.',
    required_templateName: '템플릿 명을 입력해주세요.',
    required_useOrNot: '사용여부를 입력해주세요.',
    required_duty: '직책을 입력해주세요.',
    required_hired_date: '입사일을 입력해주세요.',
    required_is_work: '재직여부를 입력해주세요.',
    required_factory_code: '공장코드를 입력해주세요.',
    required_process_code: '공정코드를 입력해주세요.',
    required_product_group_code: '제품군코드를 입력해주세요.',
    required_item_category_code: '제품분류코드를 입력해주세요.',
    required_line_code: '라인코드를 입력해주세요.',
    required_templateName: '템플릿 이름을 입력해주세요.',
    required_value: '입력값을 입력해주세요.',
    request_delete: '정말 삭제를 원하시나요?',
    request_save: '저장하시겠습니까?',
    save_failed: '저장에 실패했습니다.',
    save_successfully: '저장에 성공하였습니다.',
    update_successfully: '수정에 성공하였습니다.',
    is_required: '을(를) 입력해주세요.',
    is_invalid: '이(가) 유효하지 않습니다.',
    aas_file: 'AASX 파일',
    is_reset_reload_alert: '입력 값이 초기화됩니다. 새로고침 하시겠습니까?',

    // business
    select_business: '사업부 선택',
    user_business_no: 'NO',
    user_business_id: '사업부 아이디',
    user_business_name: '사업부명',
    user_business_zipcode: '우편번호',
    user_business_address: '사업부소재지',
    user_business_address2: '사업부소재지',
    user_business_phone: '사업부연락처',
    user_business_is_active: '사용여부',
    user_business_comment: '설명',
    user_business_modified_by: '수정자',
    user_business_modified_date: '수정일',
    user_business_created_by: '등록자',
    user_business_created_date: '등록일',
    user_business_save: '등록이 완료되었습니다.',

    // department
    user_department_id: '부서아이디',
    user_department_parent_id: '상위부서아이디',
    user_department_path: '상위부서',
    user_department_seq: '순서',
    user_department_name: '부서명',
    user_department_is_active: '사용여부',
    user_department_comment: '설명',
    selected_department: '선택된 부서',

    // user
    user_master_id: '사용자아이디',
    user_department_id: '부서아이디',
    user_master_email: '사용자이메일',
    user_master_name: '사용자',
    user_master_duty: '직책',
    user_master_position: '직무',
    user_master_is_active: '상태',
    user_master_comment: '설명',
    user_master_phone: '연락처',
    user_master_hired_date: '입사일',
    user_master_is_work: '재직여부',
    user_master_work_state: '상태',
    user_master_is_select_department: '부서가 선택되지 않았습니다.',
    user_master_hired_work: '재직',
    user_master_hired_leave: '퇴직',

    // Security_group
    security_master_id: '보안그룹아이디',
    security_master_name: '보안그룹명',
    security_master_is_active: '사용여부',
    security_master_comment: '설명',
    security_master_duplicated: '중복되는 보안그룹명이 존재합니다.',
    security_master_target_no: '순번',
    security_master_target_name: '사용자',
    security_master_target_id: '사용자아이디',
    security_master_target_table: '구분',
    security_master_notice: '검색 또는 사용자 추가 버튼으로 대상을 추가할 수 있습니다.',
    security_master_no_user: '추가할 보안 그룹이 존재하지 않습니다.',
    security_group: '보안그룹',
    security_group_business: '사업장',
    security_group_department: '부서',
    security_group_user: '사용자',
    security_user_business: '사업부',

    // Use History
    sys_log_history_no: 'NO',
    user_master_id: '사용자아이디',
    user_master_name: '사용자',
    sys_log_datetime: '사용시간',
    user_log_ip: '접속IP',
    sys_log_action: '사용구분',
    sys_log_result: '결과',
    sys_log_comment: '설명',
    sys_menu_mapping: '사용메뉴',

    // product group
    select_product_group: '제품군 선택',
    product_group_id: '제품군아이디',
    user_business_name: '사업부명',
    product_group_code: '제품군코드',
    product_group_name: '제품군명',
    product_group_is_active: '사용여부',
    product_group_comment: '설명',
    no_security_selected: '보안그룹이 선택되지 않았습니다.',

    // Item Category
    itemCategory: '제품분류관리',
    item_category_template: '제품 템플릿',
    item_category_id: '제품분류아이디',
    item_category_parent_id: '상위분류아이디',
    item_category_path: '분류경로',
    item_category_name: '제품분류명',
    item_category_code: '제품분류코드',
    item_category_is_active: '사용여부',
    item_category_comment: '설명',
    propagateToParent: '상위노드적용',
    propagateToChildren: '하위노드적용',

    // Item Master
    item_master_no: 'NO',
    itemMaster: '제품관리',
    item_master_id: '제품아이디',
    item_master_name: '품명',
    item_master_code: '품번',
    product_group: '제품군',
    item_master_eo_no: 'Eo No.',
    item_master_eo_date: 'Eo 날짜',
    item_master_customer: '고객사',
    item_master_material: '소재',
    item_master_size: '크기(너비*길이*높이)',
    item_master_weight: '중량(kg)',
    item_master_is_active: '사용여부',
    item_master_comment: '설명',
    productClassification: '제품 분류',
    item_classification: '제품 분류',

    // operation factory
    _factory: '공장',
    select_factory: '공장 선택',
    operation_factory_id: '공장아이디',
    user_business_name: '사업부명',
    operation_factory_code: '공장코드',
    operation_factory_name: '공장명',
    operation_factory_is_active: '사용여부',
    operation_factory_comment: '설명',

    // operation process
    operation_process_id: '공정아이디',
    user_business_name: '사업부명',
    operation_process_code: '공정코드',
    operation_process_name: '공정명',
    operation_process_is_active: '사용여부',
    operation_process_comment: '설명',
    operation_process_seq: '공정번호',
    operation_process_equipment: '설비관리',
    equipment_management: '설비관리',

    // operation Line
    operation_line_id: '라인아이디',
    operation_factory_name: '공장명',
    operation_line_code: '라인코드',
    operation_line_name: '라인명',
    operation_line_is_active: '사용여부',
    operation_line_comment: '설명',
    add_process: '공정 추가',
    process_management: '공정 관리',
    process: '공정',
    select_process: '공정 선택',
    line: '라인',

    // quality  badtype
    quality_badtype_no: 'NO',
    quality_badtype_id: '불량유형아이디',
    quality_badtype_code: '불량유형코드',
    quality_badtype_name: '불량유형명',
    quality_badtype_is_active: '사용여부',
    quality_badtype_comment: '설명',
    quality_badtype_active: '사용함',
    quality_badtype_not_active: '사용안함',
    quality_badtype_select_item: '제품선택',
    quality_badtype_item_name: '제품명',
    quality_badtype_item_code: '제품코드',
    quality_badtype_product_group: '제품군',

    // inspection defect type
    defect_type_no: 'NO',
    inspection_type: '검사유형',
    inspection_type_name: '검사유형',
    defect_type_id: '불량유형아이디',
    defect_type_code: '불량유형코드',
    defect_type_name: '불량유형명',
    defect_type_example_img: '불량유형예시',
    defect_type_is_active: '사용여부',
    defect_type_comment: '설명',
    select_inspectionType: '검사유형',
    applied_item: '적용제품목록',

    // inspection standard group
    inspection_library_no: 'NO',
    inspection_library_id: '검사기준아이디',
    inspection_library_type: '검사기준유형',
    inspection_library_code: '검사기준코드',
    inspection_library_name: '검사기준명',
    inspection_library_is_active: '사용여부',
    inspection_library_comment: '설명',
    inspection_library_copied_code: '복사할 검사기준그룹코드',
    inspection_library_copied_name: '복사할 검사기준그룹명',
    item_inspection_code: '검사성적서코드',
    item_inspection_name: '검사성적서명',
    item_inspection_comment: '검사성적서 설명',

    // inspection Library Condition
    inspection_library_defect_type_id: '불량유형아이디',
    inspection_library_defect_type_code: '불량유형코드',
    inspection_library_defect_type_name: '불량유형명',
    inspection_library_defect_type_formula: '측정조건계산식',
    inspection_library_defect_type_is_active: '사용여부',
    inspection_library_defect_type_comment: '설명',
    inspection_library_condition: '측정조건',
    inspection_library_condition_value: '측정규격',
    inspection_library_condition_direction: '측정규격방향',
    inspection_library_condition_gap_plus: '측정범위 +',
    inspection_library_condition_gap_minus: '측정범위 -',
    inspection_library_condition_unit: '측정단위',
    add_inspection_library: '검사기준추가',
    add_defect_type: '불량유형추가',
    add_measure_condition: '측정조건추가',

    load_defect_type: '불량유형 불러오기',
    load_inspection_standard: '검사기준 불러오기',
    add_condition: 'Add condition',

    no_measure_condition: '측정조건 없음',
    no_measure_condition_comment: '측정조건을 추가해주세요.',

    // machine_equipment
    _equipment: '설비',
    select_equipment: '설비 선택',
    machine_equipment_id: '설비아이디',
    operation_factory_name: '공장명',
    machine_equipment_type: '설비종류',
    machine_equipment_code: '설비코드',
    machine_equipment_name: '설비명',
    machine_equipment_made: '제작사',
    machine_equipment_supplier: '공급사',
    machine_equipment_modelno: '모델번호',
    machine_equipment_date: '설치일',
    machine_equipment_is_active: '사용여부',
    machine_equipment_comment: '설명',
    machine_equipment_logo: '설비사진',
    machine_equipment_duplicated: '중복 데이터가 존재합니다.',
    machine_equipment_images: '설비 이미지',
    add_equipment: '설비 추가',

    // machine_mold
    select_items: '제품 선택',
    selected: '목록',
    machine_mold_id: '금형아이디',
    operation_factory_name: '공장명',
    machine_mold_code: '금형코드',
    machine_mold_name: '금형명',
    machine_mold_no1: 'No',
    machine_mold_no: '금형호수',
    product_item_code: '품번',
    product_item_name: '품명',
    machine_mold_org: '관리부서',
    machine_mold_is_active: '사용여부',
    machine_mold_comment: '설명',
    machine_mold_images: '금형사진',
    machine_no_item: '등록된 아이템이 없습니다. 아이템 메뉴에서 아이템을 추가해주세요.',

    //Division Management
    sys_division_id: '기준정보아이디',
    sys_division_code: '기준정보코드',
    sys_division_name: '기준정보명',
    sys_division_is_active: '사용자수정여부',
    sys_division_can_edit: 'Can edit',
    sys_division_edit: 'UNLOCK',
    sys_division_not_edit: 'LOCK',
    sys_division_comment: '기준정보설명',
    sys_division_sub_parent_id: '상위항목',
    sys_division_sub_lang_code: '언어',
    sys_division_sub_caption_text: 'Caption',
    sys_division_sub_value: '항목값',
    sys_division_sub_seq: '순번',
    sys_division_sub_is_active: '사용여부',
    sys_division_sub_active: '사용함',
    sys_division_sub_not_active: '사용안함',
    sys_division_sub_default_check: '기본값',
    sys_division_sub_comment: '설명',
    sys_division_use: '사용함',
    sys_division_not_use: '사용안함',
    sys_division_select_division: '기준정보를 선택해주세요.',

    //Template Master
    template_path: '템플릿 경로',
    my_tempalte: '내 템플릿',
    template_store: '스토어',
    template_classification: '템플릿 분류명',
    select_template: '템플릿 선택',
    template_master_name: '템플릿 명',
    template_master_is_active: '사용여부',
    template_master_comment: '설명',
    template_master_icon_registor: '템플릿 아이콘 등록',
    template_master_basic_theme: '기본 테마',
    template_master_dark_theme: '어두운 테마',
    template_master_icon: '아이콘',
    templateDesc: '여기에 원하는 파일을 드래그 앤 드롭하거나 클릭하여 파일을 선택할 수 있습니다. (JPG, PNG, SVG / 120X120 사이즈 권장)',
    template_no_data: '템플릿이 없습니다.',
    template_no_data_comment: '다른 카테고리를 선택하거나 템플릿을 등록해주세요.',
    builder_option: '생성 옵션',
    template_name: '템플릿 이름',
    add_row: '행 추가',
    add_col: '열 추가',
    layout_generator: '레이아웃 설정',
    template_builder: '템플릿 생성',
    template_info_add: '하위에 생성됩니다.',
    exist_template_master_in_menu_mapping: '템플릿이 사용되는 메뉴가 있어 수정할 수 없습니다.',
    preview: '미리보기',
    no_template_classification: '템플릿 분류가 존재하지 않습니다.<br>템플릿 분류를 추가해주세요.',

    //custom success messages
    success_forgot_pwd: '로 임시 패스워드를 전송했습니다.',
    success_insert: '추가하였습니다.',
    success_update: '수정하였습니다.',
    success_remove: '삭제했습니다.',
    success_change_pwd: '비밀번호 변경이 완료되었습니다.',
    success_update_seq: '순서가 변경되었습니다.',
    success_delete_row: '선택한 데이터가 삭제되었습니다.',
    success_add_shortcut: '즐겨찾기가 추가되었습니다.',
    success_delete_shortcut: '즐겨찾기가 해제되었습니다.',

    //custom alert message
    alert_delte: '삭제하시겠습니까?',
    confirm_delete: '선택한 데이터를 삭제하시겠습니까?',
    confirm_recursive_delete: '선택하신 정보와 하위 정보까지 삭제하시겠습니까?',
    alert_remove_default_menu: '시스템 메뉴는 삭제할 수 없습니다.',

    //info
    select_mold: '몰드를 선택하세요.',
    select_item: '제품을 선택하세요.',
    info_binding_date: '날짜를 지정해서 데이터를 확인하실 수 있습니다.<br />날짜 선택은 달력 또는 선택 버튼으로 지정할 수 있습니다.',
    select_box_from_canvas: '우측 캔버스에서<br/>박스를 선택해주세요.',
    select_a_row_to_edit: '편집을 위해서 최소 1개 이상의 항목이 선택되어야 합니다.',
    search_business_group: '사업 그룹을 선택해주세요.',
    search_and_select_departments: '부서를 선택하거나 검색해주세요.',
    search_for_names_or_groups: '이름 혹은 그룹 검색',
    search_for_names_or_email: '이름 혹은 이메일을 검색해주세요.',
    search_and_select_group: '그룹 선택 및 검색',
    search_and_select_user: '사용자를 선택하거나 검색해주세요.',
    info_grant_permission: '권한을 부여할 유저를 선택해 주세요.',
    info_grant_group: '권한을 부여할 그룹을 선택해 주세요.',
    info_select_box: '우측 패널에서\n 상자를 선택해주세요.',
    info_add_group: '검색 또는 그룹 추가 버튼으로 그룹을 추가할 수 있습니다.',
    info_search_name_group: '이름 또는 그룹을 검색해주세요.',
    info_add_product_group: '추가하실 그룹을 선택해주세요.',
    info_menu_lang: '언어 선택에 따라 적용되는 메뉴명 입니다.',
    info_add_process: '추가할 공정을 선택해주세요.',
    info_process_management: '검색 또는 추가 버튼으로 공정을 추가할 수 있습니다.',
    info_equipment_management: '검색 또는 추가 버튼으로 장비를 추가할 수 있습니다.',
    create_at_least_one_row: '행을 생성해주세요.',
    selected_items: '선택한 항목',
    only_reordering: '순서 변경만 가능합니다.',

    //menu
    select_service: '서비스 선택',
    menu_path: '메뉴 경로',
    menu_mapping_name: '메뉴명',
    menu_mapping_comment: '설명',
    permission_setting: '권한설정',
    menu_security_notice: '검색 또는 추가 버튼으로 그룹을 추가할 수 있습니다.',
    binding_data: '데이터 연결',
    create: '쓰기',
    update: '수정',
    delete: '삭제',
    selected_template: '선택한 템플릿',
    card_option: '카드 옵션',
    card_title: '카드 제목',
    data_setting: '데이터 설정',
    filter_config: '설정',
    title: '제목',
    set_interval: '간격 (초)',
    date_time: '날짜 / 시간',
    plz_data_setting: '데이터를 설정해주세요.',
    add_data: '데이터 추가',
    select_dataset: '데이터셋 선택',
    alert_change_dropdown: '드롭다운 값을 변경하실 경우 이전에 선택한 카드에 대한 설정값을 잃습니다.',
    dropdown: '드롭다운',
    plz_select_comp: '컴포넌트를 선택해주세요.',
    type: '타입',
    option: '옵션',
    check: '체크',
    specify_date: '특정 날짜를 기준으로 페이지의 데이터를 확인합니다. <br />다음과 같은 방법으로 설정할 수 있습니다. : 데이트 픽커, 데이트 레인지.',
    min_value_5: '5초 보다 적을 수 없습니다.',
    component_setting: '컴포넌트 설정',
    select_component_setting: '설정할 컴포넌트를 선택해주세요.',
    component: '컴포넌트',
    component_title: '컴포넌트 제목',
    component_type: '컴포넌트 타입',
    drag_to_delete: '삭제할 컴포넌트를 드래그해주세요.',
    binding_state: '데이터 연결 상태',
    add_new_item_category: '제품 분류 추가하기',
    select_chart: '차트 선택',

    add_page_filter: '페이지 필터 추가',
    add_new_chart_type: '차트 타입 추가',
    chart_type: '차트 타입',
    view_data_sample: '데이터 샘플 보기',
    chart_data_filter: '차트 데이터 필터',
    add_filter: '필터 추가',
    y_axis: 'Y 축',
    x_axis: 'X 축',
    add_y_axis: 'Y 축 추가',
    add_x_axis: 'X 축 추가',
    add_dg_columns: '열 선택',
    binding_column: '컬럼',
    add_binding_column: '컬럼 추가',
    /* new options */
    min: '최소',
    max: '최대',
    avg: '평균',
    /* new options */
    default_period: '기간',
    alert_reset_filter: '페이지 필터 셋팅이 리셋될 수 있습니다.<br />진행하시겠습니까?',
    alert_need_more_page_filter_setting: '페이지 필터 값을 정확히 설정해주세요.',
    grid_setting: '표 설정',
    use_numbering: '순번',
    header_styling: '헤더 스타일',
    body_styling: '바디 스타일',
    styling: '스타일',
    font_style: '글꼴',
    align: '가로 정렬',
    vertical: '세로 정렬',
    font_color: '글꼴색',
    background_color: '배경색',
    global: '전역',
    raw_data_filter: '로우 데이터 필터',
    add_columns: '열 추가',
    columns: '열',
    width: '너비',
    show: '사용여부',
    link_option: '링크 설정',
    add_link: '링크 추가',
    link: '링크',
    link_open: '이동 방식',
    link_open_page: '페이지 이동',
    link_open_popup: '팝업 오픈',
    link_name: '링크 이름',
    english: '영어',
    korean: '한글',
    parameter: '파라미터',
    time: '시간',

    //Dataset Management
    dataset_name: '데이터셋 명',
    table: '테이블',
    column: '열',
    aas_path: 'AAS 경로',
    no_aas_selected: 'AAS가 선택되지 않았습니다.',
    add_dataset: '데이터셋 추가',
    dataset_setting: '데이터셋 설정',
    dataset_table: '데이터셋 테이블',
    pagination_size: '페이지 크기',

    //Master Dataset
    master_dataset_code: '데이터셋 코드',
    master_dataset_name: '데이터셋 이름',
    master_dataset_start: '데이터 시작일',
    master_dataset_end: '데이터 종료일',
    master_dataset_comment: '설명',
    raw_dataset_name: '로우 데이터 이름',
    raw_dataset: '로우 데이터',
    master_dataset_type: '데이터셋 타입',
    item_image: '아이템 이미지',

    //Abnormal settings
    abnormal_setting_no: 'NO',
    abnormal_setting_type: '분류',
    id_short: '변수명',
    abnormal_setting_usl: 'USL',
    abnormal_setting_lsl: 'LSL',
    abnormal_setting_ucl: 'UCL',
    abnormal_setting_lcl: 'LCL',
    abnormal_setting_status: '상태',
    abnormal_setting_reason: '원인',
    abnormal_setting_recommend: '추천',
    select_setting_type: '셋팅타입 선택',
    heater_temperature: '히터온도',

    // Abnormal settings
    abnormal_sign_log_no: 'NO',
    abnormal_sign_log_status: '상태',
    abnormal_sign_log_reason: '원인',
    abnormal_sign_log_recommend: '추천',
    abnormal_sign_log_created_date: '등록일시',
    date_range: '기간',
    log_action: '조치',
    log_abnormal: '이상',
    checkedDateTime: '조치시점',
    abnormal_log: '이력',

    //popup
    select_item: '제품 선택',
    select_item_notice: '제품을 선택해 주세요',

    select_line: '라인 선택',

    select_department: '부서 선택',
    select_department_notice: '부서를 선택해 주세요',

    select_product_group: '제품군 선택',
    select_product_group_notice: '제품군을 선택해 주세요',

    select_aas: 'AAS 선택',
    select_aas_notice: 'AAS를 선택해 주세요',

    select_equipment: '설비 선택',
    select_equipment_notice: '설비를 선택해 주세요',

    select_department_and_user: '부서 및 사용자 선택',
    select_department_and_user_notice: '부서 및 사용자를 선택해 주세요',

    select_process: '공정 선택',
    select_process_notice: '공정을 선택해 주세요',

    select_raw_dataset: '로우 데이터셋 선택',
    select_raw_dataset_notice: '로우 데이터셋을 선택해 주세요',

    select_mold: '몰드 선택',
    select_mold_notice: '몰드를 선택해 주세요',

    select_security_group: '보안그룹 선택',
    select_security_group_notice: '보안그룹을 선택해 주세요',

    select_process_and_line: '공정 및 라인 선택',
    select_process_and_line_notice: '공정 및 라인을 선택해 주세요',

    select_master_dataset: '데이터셋 선택',
    select_master_dataset_notice: '데이터셋을 선택해 주세요',

    select_user: '사용자 선택',
    select_user_notice: '사용자를 선택해 주세요',

    multi_chart_setting: '멀티 차트 설정',
    add_chart: '차트 추가',

    select_id_short: 'Id short 선택',
    select_id_short_notice: 'Id short를 선택해 주세요',

    //codes for data response
    MSG_94: 'AASX file reader API not responding.',
    MSG_96: 'data is bad type. choose different option.',
    MSG_97: '데이터베이스에 없는 데이터입니다.',
    MSG_98: '올바른 이메일을 입력하세요.',
    MSG_99: '요청을 보내는 중에 에러가 발생했습니다.',
    MSG_100: '성공',
    MSG_101: '이미 존재하는 이메일입니다.',
    MSG_102: '이미 존재하는 회사코드입니다.',
    MSG_103: '에러',
    MSG_104: '동일한 분류 내에 같은 분류명이 존재합니다.',
    MSG_105: '이미 존재하는 제품명입니다.',
    MSG_106: '이미 존재하는 제품코드입니다.',
    MSG_107: '이미 존재하는 불량유형코드입니다. ',
    MSG_108: '이미 존재하는 불량유형명입니다.',
    MSG_109: '이미 존재하는 설비명입니다.',
    MSG_110: '이미 존재하는 설비코드입니다.',
    MSG_111: '이미 존재하는 공장명입니다.',
    MSG_112: '이미 존재하는 공장코드입니다.',
    MSG_113: '이미 존재하는 라인명입니다.',
    MSG_114: '이미 존재하는 라인코드입니다.',
    MSG_115: '이미 존재하는 금형명입니다.',
    MSG_116: '이미 존재하는 금형코드입니다.',
    MSG_117: '이미 존재하는 공정코드입니다.',
    MSG_118: '이미 존재하는 공정명입니다.',
    MSG_119: '이미 존재하는 제품군명입니다.',
    MSG_120: '이미 존재하는 제품군코드입니다.',
    MSG_121: '이미 존재하는 보안그룹명입니다.',
    MSG_122: '수정할 수 없습니다.',
    MSG_123: '이미 존재하는 구분값입니다. ',
    MSG_124: '이미 존재하는 사업부명입니다. ',
    MSG_125: '이미 존재하는 회사코드입니다.',
    MSG_126: '이미 존재하는 부서명입니다.',
    MSG_127: '존재하지 않습니다.',
    MSG_128: '존재합니다.',
    MSG_129: '템플릿 분류명이 이미 존재 합니다.',
    MSG_130: '템플릿 명이 이미 존재합니다.',
    MSG_131: '시스템 메뉴는 삭제할 수 없습니다.',
    MSG_132: '시스템 보안그룹은 삭제할 수 없습니다.',
    MSG_133: '시스템 템플릿 분류는 삭제할 수 없습니다.',
    MSG_134: '시스템 템플릿은 삭제할 수 없습니다.',
    MSG_135: '메뉴명이 이미 존재합니다.',
    MSG_136: '4레벨의 메뉴를 추가할 수 없습다.',
    MSG_137: '삭제할 수 없습니다.',
    MSG_138: '패키지명이 이미 존재합니다.',
    MSG_139: '데이터셋명이 이미 존재합니다.',
    MSG_140: '마스터 데이터셋명이 이미 존재합니다.',
    MSG_141: '동일한 마스터 데이터셋이 이미 존재합니다.',
    MSG_142: '불량유형명이 이미 존재합니다.',
    MSG_143: '불량유형코드가 이미 존재합니다.',
    MSG_144: '검사표준명이 이미 존재합니다.',
    MSG_145: '검사표준코드가 이미 존재합니다.',
    MSG_146: '해당 불량유형이 검사표준에 이미 존재합니다.',
    MSG_147: '검사기준코드가  이미 존재합니다.',
    MSG_148: '검사기준명이 이미 존재합니다.',
    MSG_149: '불량유형분류명이 이미 존재합니다.',
    MSG_150: '이미 존재하는 이상알림설정 입니다.',
    MSG_151: '이 이메일은 다른 회사에 이미 존재합니다.',
    MSG_152: '동일한 이름의 폴더가 존재하여, 같은 경로로 이동할 수 없습니다.',
    MSG_160: '다음 항목에서 종속성을 제거하십시오.',
    MSG_163: '삭제할 수 없습니다.',
    //MSG_125: "탬플릿 분류를 삭제할 수 없습니다."

    after_business_add: '부서 등록 후 사용할 수 있습니다. 부서 등록 페이지로 이동합니다.',
};
