// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.abnormal-filter {
    padding-bottom: 20px;
    margin: 0px 20px 20px;
    border-bottom: 1px dashed var(--border);
}
.abnormal-filter .dx-field {
    margin: 0px !important;
}
.abnormal-filter .dx-field {
    flex-direction: row !important;
    padding: 0px !important;
    width: calc(100% - 40px);
    align-items: center;
}
.abnormal-filter .dx-field .dx-field-label {
    width: 200px;
    margin: 0px !important;
}
.abnormal-filter .dx-field .dx-field-label p {
    overflow: hidden;
    text-overflow: ellipsis;
}
.abnormal-filter .dx-field .dx-field-value {
    width: calc(100% - 200px) !important;
    margin: 0px !important;
}
.abnormal-filter .filter-dropdown {
    align-items: flex-end;
}
.abnormal-filter .filter-dropdown > div:first-child {
    width: 100%;
}
.abnormal-filter .filter-dropdown .min-max,
.abnormal-filter .filter-dropdown .equals {
    width: calc(100% - 200px);
}`, "",{"version":3,"sources":["webpack://./src/contents/styles/dataManagement/abnormal-setting.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,qBAAqB;IACrB,uCAAuC;AAC3C;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,8BAA8B;IAC9B,uBAAuB;IACvB,wBAAwB;IACxB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,sBAAsB;AAC1B;AACA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;IACI,oCAAoC;IACpC,sBAAsB;AAC1B;AACA;IACI,qBAAqB;AACzB;AACA;IACI,WAAW;AACf;AACA;;IAEI,yBAAyB;AAC7B","sourcesContent":[".abnormal-filter {\n    padding-bottom: 20px;\n    margin: 0px 20px 20px;\n    border-bottom: 1px dashed var(--border);\n}\n.abnormal-filter .dx-field {\n    margin: 0px !important;\n}\n.abnormal-filter .dx-field {\n    flex-direction: row !important;\n    padding: 0px !important;\n    width: calc(100% - 40px);\n    align-items: center;\n}\n.abnormal-filter .dx-field .dx-field-label {\n    width: 200px;\n    margin: 0px !important;\n}\n.abnormal-filter .dx-field .dx-field-label p {\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n.abnormal-filter .dx-field .dx-field-value {\n    width: calc(100% - 200px) !important;\n    margin: 0px !important;\n}\n.abnormal-filter .filter-dropdown {\n    align-items: flex-end;\n}\n.abnormal-filter .filter-dropdown > div:first-child {\n    width: 100%;\n}\n.abnormal-filter .filter-dropdown .min-max,\n.abnormal-filter .filter-dropdown .equals {\n    width: calc(100% - 200px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
